.container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding: 80px;
  font-size: 1em;
  color: var(--gray);
}

.skill {
  flex: 1 0 0;
  padding: 20px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100px;
  background-color: var(--current-bg-color);
  box-shadow: var(--current-box-shadow);
  border-radius: 10px;
  cursor: pointer;
  color: var(--current-fg-color);
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease-in-out;
}

.skill:hover {
  color: white;
}

.skillData {
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.icon {
  height: 3em;
  filter: grayscale(1);
  transition: filter 0.3s ease-in-out;
}

.iconBackground {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.skill:hover .icon {
  filter: none;
}

.backgroundContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.backgroundFilter {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  border-radius: 10px;
  backdrop-filter: blur(5px) contrast(0.5);
}

.skill:hover .backgroundContainer {
  opacity: 1;
}

@media (max-width: 700px) {
  .container {
    padding: 20px;
  }
}
