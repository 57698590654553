@import url('https://fonts.googleapis.com/css2?family=Marko+One&family=Montserrat:wght@400;700&display=swap');

:root {
  --bg: #f8f7f3;
  --primary: #f1c984;
  --secondary: #e29e7d;
  --accent: #eab007;
  --gray: #868585;
  --light-gray: #c3c3c3;
  --font-primary: 'Montserrat', sans-serif;
  --font-secondary: 'Marko One', serif;
}

* {
  box-sizing: border-box;
  font-family: var(--font-primary);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
  display: flex;
  flex-direction: column;
}

html,
body #root {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  position: relative;
}

html {
  height: auto;
}
