.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.menu {
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 35%;
  text-align: center;
  margin: 20px auto;
}

.menu > * {
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  font-size: 12px;
  font-family: var(--font-secondary);
  font-weight: bold;
  cursor: pointer;
  padding: 10px 0;
  background-color: transparent;
  border: none;
  outline: none;
}

.menu > *[data-active='true'] {
  color: var(--accent);
}

.content {
  padding: 20px 50px;
  flex-grow: 1;
  display: flex;
}

@media (max-width: 700px) {
  .container {
    max-height: calc(100vh - 100px - 50px - 12px);
    overflow-y: auto;
    margin-top: 10px;
  }

  .content {
    padding: 10px 10px 80px;
  }

  .menu {
    width: 80%;
  }
}
