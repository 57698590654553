.container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: var(--gray);
}

.container > * {
  font-family: var(--font-secondary);
}
