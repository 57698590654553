.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.salute {
  color: #000;
  font-size: 100px;
  font-family: Montserrat;
  font-weight: 700;
}

.name {
  color: #000;
  font-size: 40px;
  font-family: Montserrat;
  font-weight: 700;
  margin-bottom: 10px;
}

.name > :last-child {
  color: white;
}

.title {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 700;
  margin: 10px 0;
}

.variableTitle {
  text-align: center;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 700;
  background: linear-gradient(90deg, #f1c984 0%, #e29e7d 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.social {
  margin-top: 170px;
  margin-bottom: -200px;
}

@media (max-width: 700px) {
  .salute {
    font-size: 80px;
  }

  .name {
    font-size: 30px;
  }

  .variableTitle,
  .title {
    font-size: 16px;
  }

  .social {
    margin-top: 100px;
    margin-bottom: -100px;
  }
}
