.container {
  width: calc(100vw - 480px);
  margin-left: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  min-height: 60%;
  min-width: min(80%, 60vh);
}

.box {
  border: 1px solid var(--gray);
  border-radius: 10px;
  padding: 10px;
  aspect-ratio: 1;
  background-color: var(--gray);
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.box:not([data-active='true']):hover {
  background-color: transparent;
  color: var(--gray);
  border-color: var(--gray);
  box-shadow: inset 0 0 10px -5px black;
}

.box:nth-child(3n + 1) {
  border-color: var(--accent);
  background-color: transparent;
  color: var(--accent);
}

.box:not([data-active='true']):nth-child(3n + 1):hover {
  background-color: var(--accent);
  border-color: transparent;
  color: white;
  box-shadow: 0 0 10px -5px black;
}

.box[data-active='true'] {
  --current-bg-color: var(--light-gray);
  --current-fg-color: white;
  --current-box-shadow: 20px 20px 60px #a6a6a6, -20px -20px 60px #e0e0e0;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 100px);
  width: calc(100vw - 200px);
  cursor: initial;
  padding: 40px;
  background-color: var(--current-bg-color);
  color: var(--current-fg-color);
  border-color: transparent;
  z-index: 101;
  overflow-y: auto;
}

.box:nth-child(3n + 1)[data-active='true'] {
  --current-bg-color: white;
  --current-fg-color: var(--gray);
  --current-box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;

  color: var(--accent);
  border-color: transparent;
}

.boxTitle {
  font-weight: bold;
  font-size: 20px;
  font-family: var(--font-secondary);
}

.backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgb(255 255 255 / 33%);
  backdrop-filter: blur(7.5px);
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  cursor: pointer;
}

.backdrop[data-variant='true'] {
  background-color: rgb(234 176 7 / 33%);
}

.boxContent {
  opacity: 0;
  position: absolute;
  height: 0;
  width: 0;
  transition: none;
  overflow: hidden;
  margin-top: 20px;
}

.box[data-active='true'] > .boxContent[data-opened='true'] {
  opacity: 1;
  position: relative;
  height: unset;
  width: unset;
}

.exitBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  display: none;
  fill: var(--current-fg-color);
}

.exitBtn[data-opened='true'] {
  cursor: pointer;
  display: initial;
}

@media (max-width: 700px) {
  .container {
    width: unset;
    margin: auto 0 0;
  }

  .content {
    padding: 20px;
  }

  .box:not([data-active='true']):nth-child(3n + 1) {
    border-color: white;
    color: white;
  }

  .box:not([data-active='true']):nth-child(3n + 1):hover {
    color: var(--gray);
    background-color: white;
  }

  .box[data-active='true'] {
    height: calc(100vh - 40px);
    width: calc(100vw - 20px);
    padding: 10px;

    --current-box-shadow: 9px 9px 18px #a6a6a6, -9px -9px 18px #e0e0e0;
  }

  .box:nth-child(3n + 1)[data-active='true'] {
    --current-box-shadow: 9px 9px 18px #d9d9d9, -9px -9px 18px #ffffff;
  }
}
