.container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 20px;
  padding: 20px 10vw;
}

.achievement {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  flex: 1 0;
  min-width: 15vw;
  text-align: center;
  color: var(--gray);
}

.achievement * {
  transition: all 0.3s ease-in-out;
}

.graphContainer {
  position: relative;
}

.graph {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: var(--gray);
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
  cursor: pointer;
}

.graphContainer:hover .graph {
  filter: none;
}

.graphContainer:hover ~ * {
  color: black;
}

.iconContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  padding: 5px;
  height: calc(1em + 5px);
  width: calc(1em + 5px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray);
}

.icon {
  height: 1em;
  width: 1em;
}

.icon[data-type='trophy'] {
  fill: var(--accent);
}

.icon[data-type='academic'] {
  fill: #008fc7;
}

.icon[data-type='event'] {
  fill: #5aad00;
}

.icon[data-type='work'] {
  fill: #ad6200;
}

@media (max-width: 700px) {
  .achievement {
    min-width: unset;
    width: 50vw;
  }
}
