.blob {
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.6s ease-in-out;
}

.blob[data-type='0'] {
  left: 50%;
  top: 50%;
  transform: scale(3) translate(178px, 212px);
  width: 650px;
}

.blob[data-type='1'] {
  transform: scale(1.5) translate(15%, 140px);
  width: max(1000px, 100vw);
}

.blob[data-type='2'] {
  transform: scale(1.7) translate(-290px, -280px);
  width: 1200px;
}

.blob[data-type='3'] {
  transform: scale(1.2) translate(45px, 60px);
  width: 1200px;
}

@media (max-width: 700px) {
  .blob[data-type='0'] {
    transform: scale(2.3) translate(145px, 181px);
  }

  .blob[data-type='1'] {
    transform: scale(1.5) translate(130px, 130px);
  }

  .blob[data-type='3'] {
    transform: scale(1.2) translate(-25px, 20px);
  }
}
