.blob {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.menu {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  gap: 10px;
}

.menuItem {
  outline: none;
  background-color: transparent;
  border: none;
  flex-basis: 0;
  flex-grow: 1;
  padding: 20px 20px 0;
  cursor: pointer;
  font-weight: bold;
}

.menuItem[data-active='true'] {
  color: var(--secondary);
  font-weight: bold;
  border-bottom: 2px solid currentColor;
}

.menu[data-contrast='true'] > .menuItem[data-active='true'] {
  color: white;
}

.title {
  color: #fff;
  text-align: center;
  font-size: 60px;
  font-family: var(--font-secondary);
  display: flex;
  padding: 20px;
  text-transform: uppercase;
}

.floatingBtn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  color: var(--secondary);
  fill: var(--secondary);
  border-radius: 50px;
  border: 1px solid var(--secondary);
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  background-color: var(--bg);
}

.floatingBtn:hover {
  background-color: var(--secondary);
  color: white;
  fill: white;
}

.floatingBtn > svg {
  height: 1em;
}

@media (max-width: 700px) {
  .menu {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .menu[data-blur='true'] {
    backdrop-filter: blur(10px);
  }

  .menuItem {
    padding-bottom: 5px;
  }
}
