.container {
  padding: 0 50px 20px;
  gap: 20px;
  display: flex;
  max-height: calc(100vh - 100px - 10px);
  overflow-y: auto;
  position: relative;
}

.infoContainer {
  margin-top: 70px;
  width: 300px;
  padding: 40px 20px 20px 0;
  max-height: calc(100% - 100px);
  overflow: auto;
}

.info {
  color: var(--gray);
  font-size: 12px;
  font-family: var(--font-secondary);
  display: flex;
  flex-direction: column;
}

.info[data-empty='true'] {
  height: 100%;
  align-items: center;
  justify-content: center;
}

.info ul {
  padding-left: 10px;
}

.info ul li {
  margin-bottom: 10px;
}

.timeline {
  overflow-y: auto;
  width: 100%;
  position: relative;
  display: flex;
  margin-right: -50px;
  margin-bottom: -20px;
  padding-bottom: 20px;
}

.timeline > :last-child {
  padding-right: 50px;
}

.secondCol {
  position: absolute;
}

.secondColNode {
  width: calc(350px + 200px);
}

.thirdColNode {
  width: calc(350px + 200px + 200px);
}

.fourthColNode {
  width: calc(350px + 200px + 200px + 200px);
}

.poi {
  min-width: 50px;
  flex-direction: column;
}

.poiText {
  display: flex;
  font-size: 0.6em;
  text-align: center;
}

.cakeIconContainer {
  background-color: white;
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--accent);
}

.cakeIcon {
  height: 0.8em;
  fill: var(--accent);
}

.workIcon {
  height: 100%;
  border-radius: 50%;
}

.selectedWork > :last-child > :first-child {
  border-color: var(--secondary);
}

.exitInfo {
  display: none;
}

.noWorkSelected {
  width: 100%;
  filter: grayscale(1);
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noWorkSelected > img {
  width: 100%;
  object-fit: contain;
}

.link {
  color: var(--secondary);
}

@media (max-width: 700px) {
  .container {
    flex-direction: column-reverse;
    margin-top: 50px;
    overflow-y: unset;
    max-height: calc(100vh - 100px - 30px - 50px - 20px);
    gap: 0;
  }

  .timeline {
    flex-grow: 1;
    margin: 0 -50px;
    width: calc(100% + 100px);
  }

  .infoContainer {
    margin-top: 0;
    width: calc(100% + 100px);
    padding: 0;
    max-height: 50vh;
    margin-left: -50px;
    overflow: unset;
    display: none;
    margin-bottom: -20px;
    position: relative;
  }

  .infoContainer[data-empty='false'] {
    display: flex;
    max-height: 40vh;
    background-color: white;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 10px -5px var(--gray);
    padding: 0;
  }

  .info[data-empty='false'] {
    padding: 20px 20px 80px;
    overflow-y: auto;
  }

  .exitInfo {
    display: flex;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
  }
}
